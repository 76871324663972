<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="mortgage" backlink="/mortgage/obligations" />
      <div class="content">
        <div class="content__container">
          <Question
            service="mortgage"
            name="problem"
            question="question_4"
            successUrl="/mortgage/about-problems"
            negativeUrl="/mortgage/delay"
            :droppedSteps="['/mortgage/about-problems']"
            :negativeMissedSteps="['/mortgage/about-problems']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
export default {
  name: 'Mortgage_problems',
  components: { Backlink, Question }
}
</script>
